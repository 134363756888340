import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardSubtitle,
  CardText,
  Badge,
  CardTitle,
} from "reactstrap";
import { MdTableRestaurant } from "react-icons/md";
import axios from "axios";
import WaiterModal from "./WaiterModal";
import MyNavbar from "../MyNavbar";
const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

function Waiter() {
  const [data, setData] = useState(null);
  const [item, setItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  const handleClick = (item) => {
    setOpen(true);
    setItem(item);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${config.apiUrl}/popOver/getAllData`,
          {
            RestaurantId: 1,
          }
        );
        setNotificationData(response.data.Result);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const interval = setInterval(fetchData, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/table/getAllData`, {
          RestaurantId: 1,
        });
        const Notificationresponse = await axios.post(
          `${config.apiUrl}/popOver/getAllData`,
          {
            RestaurantId: 1,
          }
        );
        setNotificationData(Notificationresponse.data.Result);
        setData(response.data.Result);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const interval = setInterval(fetchData, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);
console.log(notificationData)
  if (!data) return null;
  return (
    <>
      <MyNavbar text={"Bildirimler"} navbarType={"waiters"} notificationData={notificationData} />
      <div className="container fix-margin">
        <div className="Waiter-Container">
          <div className="row">
            {data.map((item, i) => (
              <div
                className="col-6 col-md-4 col-xl-3 my-2 product-card-col"
                key={i}
              >
                <Card
                  className="card-body waiter-card-body border border-1 shadow-sm"
                  inverse
                >
                  <div className="d-flex justify-content-end">
                    {item.IsOrderRequestExists ? (
                      <Badge color="success" pill>
                        Sipariş Var
                      </Badge>
                    ) : (
                      <Badge color="danger" pill>
                        Sipariş Yok
                      </Badge>
                    )}
                  </div>

                  <MdTableRestaurant className="Table-Icon"></MdTableRestaurant>
                  <CardBody className=" Products-Card-Body">
                    <CardTitle
                      className="card-title card-text text-center text-dark"
                      tag="h2"
                    >
                      {item.TableName}
                    </CardTitle>
                    <div className="waiter-button-container ">
                      <Button
                      disabled={!item.IsOrderRequestExists}
                        onClick={() => {
                          handleClick(item);
                        }}
                        size="lg"
                      >
                        İşlemler
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        </div>
        <WaiterModal setOpen={setOpen} open={open} item={item}></WaiterModal>
      </div>
    </>
  );
}

export default Waiter;
