import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { BsWallet2 } from "react-icons/bs/";
import { AiOutlineClose } from "react-icons/ai";
import { Button, ButtonGroup, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { OrderModalAction } from "../Redux/Actions/OrderModal.action";
import { CartActions } from "../Redux/Actions/Cart.action";
import { useEffect } from "react";
import { MdRemoveShoppingCart } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import CustomInput from "./Global Components/Input";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

function OrderModal({ modalType }) {
  let ModalStatus = useSelector((state) => state.OrderModal.onOpenModal);
  let cart = useSelector((state) => state.CartReducer.cart);
  const { restId, tableId, menuId } = useParams();
  const [modalFlow, setModalFlow] = useState(1);
  const [orderNote, setOrderNote] = useState("");
  const dispatch = useDispatch();
  const [tables, setTables] = useState([]);
  const [newTableId, setNewTableId] = useState(null);

  const onCloseModal = () => {
    setModalFlow(1);
    dispatch(OrderModalAction.CloseOrderModal());
  };

  const nextButton = () => {
    setModalFlow(2);
  };
  const backButton = () => {
    setModalFlow(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/table/getAllData`, {
          RestaurantId: 1,
        });
        setTables(response.data.Result);
        const item = response.data.Result.find(
          (item) => item.TableNumber === Number(tableId)
        ).Id;
        setNewTableId(item);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);
  console.log(tableId);

  console.log(tables);
  // const IncreaseQuantity = (product) => {
  //   dispatch(CartActions.IncreaseQuantity(product));
  // };

  // const DecreaseQuantity = (product) => {
  //   dispatch(CartActions.DecreaseQuantity(product));
  // };

  const deleteToCart = (product) => {
    dispatch(CartActions.deleteToCart(product));
  };

  let totalPrice = 0;

  cart.map(
    (cartItem) => (totalPrice += cartItem.quantity * cartItem.ProductPrice)
  );

  let newCart = [];
  cart.map((cartItem) => {
    if (cartItem.quantity) {
      for (var i = 0; i < cartItem.quantity; i++) {
        newCart.push(cartItem.Id);
      }
    }
  });

  const sendOrder = async () => {
    const reqBody = {
      RestaurantId: Number(restId),
      ProductId: newCart,
      OrderId: newTableId + 1,
      TableId: newTableId,
      OrderNotes: orderNote,
      CreateUserId: 1,
    };
    try {
      await axios.post(`${config.apiUrl}/orderRequest/addData`, reqBody);
      onCloseModal();
      dispatch(CartActions.resetToCart());
      toast("Siparişleriniz Garson Onayına Gönderildi.");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div>
        <Modal
          open={ModalStatus}
          onClose={onCloseModal}
          center
          blockScroll={true}
          animationDuration={0}
          showCloseIcon={false}
        >
          <div className="Modal-Fixed-Nav">
            <div className="Modal-Nav p-3 mb-1 shadow">
              <button
                className="Modal-Close-Button "
                onClick={() => onCloseModal()}
                // onTouchStart={() => onCloseModal()}
              >
                <AiOutlineClose />
              </button>
              <div className="Modal-Nav-Right-Container">
                <BsWallet2 className="Modal-ico me-1" />
                <h5 className="me-1">Tutar:</h5>
                <h5>{totalPrice}&#x20BA; </h5>
              </div>
            </div>
          </div>

          {cart.length === 0 || cart === undefined ? (
            <div className="No-Cart">
              <h6 className="text-center">Siparişiniz bulunmamaktadır.</h6>
              <MdRemoveShoppingCart className="No-Cart-Icon" />
            </div>
          ) : (
            modalFlow === 1 &&
            cart.map((product, i) => (
              <div className="row no-gutters" key={i}>
                <div className="card mb-3">
                  <div className="row">
                    <div className="col-4 d-flex justify-content-center align-items-center p-0">
                      <img
                        src={product.ProductImageUrl}
                        className="card-img modal-card-img img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="col-5 d-flex">
                      <div className="card-body d-flex justify-content-center align-items-center">
                        <div>
                          <h5 className="card-title text-center">
                            {product.ProductName}
                          </h5>
                          <small className="text-muted">3 mins ago</small>
                          <RiDeleteBin5Fill
                            className="Order-Delete-Icon mt-4"
                            onClick={() => deleteToCart(product)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-3 d-flex justify-content-center align-items-center">
                      <div>
                        <h2 className="text-center">
                          {product.ProductPrice}
                          &#x20BA;
                        </h2>
                        <ButtonGroup>
                          {/* <Button
                            className="Modal-Amount-Button me-1"
                            color="danger"
                            onClick={() => DecreaseQuantity(product)}
                          >
                            <p className="fw-semibold text-center">-</p>
                          </Button> */}
                          <div>
                            <small>{"Adet: " + product.quantity}</small>
                          </div>
                          {/* <Button
                            className="Modal-Amount-Button ms-1"
                            color="danger text-center"
                            onClick={() => IncreaseQuantity(product)}
                          >
                            <p className="fw-semibold text-center">+</p>
                          </Button> */}
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
          {modalFlow === 2 && (
            <div className="row no-gutters">
              <div className="card mb-3">
                <CustomInput
                  label={"Sipariş Notu"}
                  multiline
                  value={orderNote}
                  onChange={setOrderNote}
                  type="text"
                />
              </div>
            </div>
          )}
          {cart.length !== 0 && cart !== undefined && (
            <div className="Modal-Fixed-Foot">
              <div className="Modal-foot p-3">
                {modalFlow === 1 && (
                  <button
                    className="btn btn-outline-primary card-button btn-lg w-75"
                    onClick={() => nextButton()}
                  >
                    <h5 className="m-0">İleri</h5>
                  </button>
                )}
                {modalFlow === 2 && (
                  <div>
                    <button
                      className="btn btn-outline-primary card-button btn-lg me-3 w-40"
                      onClick={() => backButton()}
                    >
                      <h5 className="m-0">Geri</h5>
                    </button>
                    <button
                      className="btn btn-outline-primary modalConfirmButton btn-lg w-40"
                      onClick={sendOrder}
                    >
                      <h5 className="m-0">Onayla</h5>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </Modal>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
}

export default OrderModal;
