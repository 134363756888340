import axios from "axios";
// const querystring = require("querystring");

const sendRequest = {
  post: (url, data, headers) => handleRequest(url, data, "post", headers),
  put: (url, data, headers) => handleRequest(url, data, "put", headers),
  get: (url, data) => handleRequest(url, data, "get"),
  delete: (url, data) => handleRequest(url, data, "delete"),
};

export default sendRequest;

function handleRequest(url, data, method, headers = {}) {
  console.log(data);
  // if (method === "get" && data) {
  //   url = url + "?" + querystring.stringify(data);
  // }
  return new Promise((resolve, reject) => {
    request
      .request({
        method,
        url,
        data,
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => reject(err));
  });
}
const request = axios.create({
  baseURL: "https://getmenu.com.tr:4000",
  withCredentials: false,
});

